<template>
  <div class="container mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <div>
        <span class="is-pulled-right">
          <a class="button mr-2" @click="pullSalesModal.opened = true">Pull Sales</a>
        </span>
        
        <h1 class="title">Products Sales</h1>

        <div class="mt-3">
          <div v-if="waiting">
            <span class="icon is-medium is-size-4">
              <i class="fas fa-spinner fa-pulse"></i>
            </span>
          </div>
          <div v-else>
            <div class="columns mt-4">
              <div class="column field mb-0 pb-0">
                <p class="control has-icons-left">
                  <span class="select">
                    <select v-model="filter">
                      <option v-for="(c, i) in classOptions"  :key="'product-class-option' + i">
                        {{c}}
                      </option>
                    </select>
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-filter"></i>
                  </span>
                </p>
              </div>
              <div class="column field  mb-0 pb-0">
                <p class="control has-icons-left">
                  <input class="input" type="text" placeholder="Search" v-model="search">
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </p>
              </div>
            </div>

            <div class="table-container">
            <table class="table is-fullwidth is-hoverable is-striped my-table">
              <thead>
                <th>#</th>
                <th>NS Id Index</th>
                <th>NS SKU</th>
                <th>Class</th>
              </thead>
              <tbody>
                <tr v-for="(p, i) in showingProducts" :key="'product-' + i">
                  <td>{{i+1}}</td>
                  <td>
                    <router-link :to="'/product-sales/' + p.itemId">{{p.itemId}}</router-link>
                  </td>
                  <td>
                    <router-link :to="'/product-sales/' + p.itemId">{{p.itemSku}}</router-link>
                  </td>
                  <td>{{p.className}}</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
      
      
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>

      <pull-sales-modal :opened="pullSalesModal.opened" @closePullSalesModal="pullSalesModal.opened=false"></pull-sales-modal>
    </div>
  </div>
</template>

<script>
import PullSalesModal from '@/components/modals/PullSalesModal'

export default {
  name: 'ProductsSales',
  components: {
    PullSalesModal
  },
  data () {
    return {
      error: '',
      waiting: false,
      search: '',
      filter: 'All',
      products: [],
      pullSalesModal: {
        opened: false
      },
      classOptions: [],
      search: '',
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    showingProducts () {
      var vm = this
      return this.products.filter(p => {
        if (vm.filter == 'All') {
          return true
        }
        return p.className == vm.filter
      }).filter(p => {
        var search = vm.search.toLowerCase()
        return p.itemId.toLowerCase().includes(search)
          || p.itemSku.toLowerCase().includes(search)
          || p.className.toLowerCase().includes(search)
      })
    },
  },
  watch: {
    filter: function (val) {
    },
  },
  methods: {
    getProducts () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-sales-for-products/').then(resp => {
        this.products = resp.body
        this.products.sort((a, b) => {
          var aSku = a.itemSku || a.itemId
          var bSku = b.itemSku || b.itemId
          return aSku.localeCompare(bSku)
        })
        var classNames = new Set()
        this.products.forEach(p => classNames.add(p.className))
        this.classOptions = [...classNames]
        this.classOptions.sort((a, b) => a.localeCompare(b))
        this.classOptions.unshift('All')
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
  },
  mounted () {
    this.getProducts()
  },
}
</script>

<style scoped>
</style>
